@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700;900&display=swap');

*,
*::before,
*::after {
  box-sizing: border-box;
}

* {
  margin: 0;
}

body {
  line-height: 1.5;
  -webkit-font-smoothing: antialiased;
  font-family: 'Roboto', sans-serif !important;
}

img,
picture,
video,
canvas,
svg {
  display: block;
  max-width: 100%;
}

input,
button,
textarea,
select {
  font: inherit;
}

p,
h1,
h2,
h3,
h4,
h5,
h6 {
  overflow-wrap: break-word;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

#root {
  isolation: isolate;
}

a:active,
a:focus,
a:hover {
  text-decoration: none !important;
}

.shining {
  --rs-placeholder: var(--rs-gray-400);
  --rs-placeholder-active: var(--rs-gray-300);
  background: linear-gradient(-45deg, var(--rs-placeholder) 25%, var(--rs-placeholder-active) 37%, var(--rs-placeholder) 63%);
  background-size: 400% 100% !important;
  animation: shine 2s ease infinite;
}

@keyframes shine {
  0% {
    background-position: 100% 50%;
  }

  100% {
    background-position: 0 50%;
  }
}

.text-overflow {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.text-overflow-2 {
  -webkit-line-clamp: 2;
}

.button-outlined {
  --rs-btn-primary-bg: var(--rs-primary-50);
  --rs-btn-primary-text: var(--rs-primary-400);
  --rs-btn-primary-hover-bg: var(--rs-primary-50);
  --rs-btn-primary-active-bg: var(--rs-primary-100);
  border: 1px solid var(--rs-primary-400) !important;
}

.button-outlined.deactive {
  --rs-btn-primary-bg: transparent;
}

.button-cancel {
  --rs-btn-primary-bg: var(--rs-red-700);
  --rs-btn-primary-text: var(--rs-gray-50);
  --rs-btn-primary-hover-bg: var(--rs-red-600);
  --rs-btn-primary-active-bg: var(--rs-red-600);
}

.button-outlined-blue {
  --rs-btn-primary-bg: transparent;
  --rs-btn-primary-text: #1B66F2;
  --rs-btn-primary-hover-bg: transparent;
  --rs-btn-primary-active-bg: transparent;
  border: 1px solid #1B66F2 !important;
}

.swiper-button-next,
.swiper-button-prev {
  --swiper-navigation-sides-offset: 0px;
  --swiper-navigation-size: 17px;
  --swiper-theme-color: #fff;
  visibility: hidden;
  height: 100% !important;
  top: 8px !important;
  width: 12% !important;
}

.venue-details-swiper .swiper-button-next,
.venue-details-swiper .swiper-button-prev {
  --swiper-navigation-sides-offset: 18px;
  --swiper-navigation-size: 36px;
  --swiper-theme-color: #fff;
  visibility: visible;
  height: fit-content !important;
  top: unset !important;
  bottom: 20px !important;
  width: fit-content !important;
}

.venue-details-swiper-mobile .swiper-button-next,
.venue-details-swiper-mobile .swiper-button-prev {
  --swiper-navigation-sides-offset: 0px;
  --swiper-navigation-size: 18px;
  --swiper-theme-color: #fff;
  visibility: visible;
  height: 100% !important;
  top: unset !important;
  bottom: 0px !important;
  width: 10% !important;
}

@media screen and (min-width: 768px) {

  .venue-details-swiper-mobile .swiper-button-next,
  .venue-details-swiper-mobile .swiper-button-prev {
    --swiper-navigation-sides-offset: 0px;
    --swiper-navigation-size: 28px;
    --swiper-theme-color: #fff;
    height: 70px !important;
  }

}

.swiper,
.swiper * {
  user-select: none;
}

.swiper:not(.venue-details-swiper):hover .swiper-button-next,
.swiper:not(.venue-details-swiper):hover .swiper-button-prev {
  visibility: visible;
}

.swiper-pagination-bullet {
  --swiper-pagination-color: #fff;
  --swiper-pagination-bullet-inactive-color: #fff;
  --swiper-pagination-bullet-inactive-opacity: 1;
  border: none
}

.swiper-pagination-bullet.swiper-pagination-bullet-active {
  border: 1px solid var(--rs-primary-300);
  --swiper-pagination-bullet-width: 10px;
  --swiper-pagination-bullet-height: 10px;
  vertical-align: middle;
  margin-top: 0px !important;
}

.venue-list-swiper .swiper-pagination {
  display: none
}

@media screen and (max-width: 768px) {
  .venue-list-swiper .swiper-pagination {
    display: block
  }

}

.shadow-xss {
  box-shadow: 0px 4px 4px -2px rgba(24, 39, 75, 0.12);
}

.rs-form-control.rs-form-control-wrapper {
  width: 100%;
}

.custom-width-modal.rs-modal-sm {
  max-width: 615px;
  width: 90%;
}

.pulse {
  width: 100%;
  height: 100%;
  position: absolute;
  border: 2px solid var(--rs-primary-200);
  border-radius: 50%;
  top: 50%;
  left: 50%;
  opacity: 0;
  transform: translate(-50%, -50%);
  animation: pulsate infinite 2s
}

@keyframes pulsate {
  0% {
    transform: translate(-50%, -50%) scale(1, 1);
    opacity: 1;
  }

  100% {
    transform: translate(-50%, -50%) scale(1.2, 1.2);
    opacity: 0;
  }
}


.progress-bar {
  height: 6px;
  background-color: var(--primary-op-20);
  width: 100%;
  overflow: hidden;
}

.progress-bar-value {
  width: 100%;
  height: 100%;
  background-color: var(--rs-primary-400);
  animation: indeterminateAnimation 1.2s infinite linear;
  transform-origin: 0% 50%;
}

@keyframes indeterminateAnimation {
  0% {
    transform: translateX(0) scaleX(0);
  }

  40% {
    transform: translateX(0) scaleX(0.4);
  }

  100% {
    transform: translateX(100%) scaleX(0.5);
  }
}

.expandable-textarea {
  text-wrap: nowrap;
  overflow-x: hidden !important;
  /* height: 34px; */
}

.expandable-textarea:focus {
  text-wrap: wrap;
  height: fit-content
}

.swiper-lazy-preloader {
  --swiper-preloader-color: var(--rs-primary-400);
  top: 30% !important;
}

.interested-in-nav {
  visibility: visible;
  width: 5% !important;
  --swiper-theme-color: #000;
  z-index: 2;
}

@media screen and (max-width:768px) {
  .interested-in-nav {
    visibility: hidden;
  }

}