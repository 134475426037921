.rs-theme-light,
:root {
  --bg-50: #FFFFFF;
  --bg-100: #F9F9FC;
  --bg-200: #F0F0F4;
  --bg-300: #E2E2E5;
  --bg-400: #C6C6C9;
  --bg-500: #909194;
  --bg-600: #5D5E61;
  --bg-700: #45474A;
  --bg-800: #2F3033;
  --bg-900: #000000;


  --primary-op-20: #9C27B033;
  --primary-op-25: #9C27B040;
  --primary-op-80: #9C27B080;

  --text-title: var(--bg-900);
  --text-subtitle: var(--bg-300);
  --text-muted: var(--bg-400);
  /* ---- */
  --rs-gray-50: #FFFFFF;
  --rs-gray-100: #F9F9FC;
  --rs-gray-200: #F0F0F4;
  --rs-gray-300: #E2E2E5;
  --rs-gray-400: #C6C6C9;
  --rs-gray-500: #909194;
  --rs-gray-600: #5D5E61;
  --rs-gray-700: #45474A;
  --rs-gray-800: #2F3033;
  --rs-gray-900: #000000;

  --rs-primary-50: #FFE6FF;
  --rs-primary-100: #E6C5F0;
  --rs-primary-200: #D296E0;
  --rs-primary-300: #BC6BCF;
  --rs-primary-400: #AB47BF;
  --rs-primary-500: #9C27B0;
  --rs-primary-600: #981BAB;
  --rs-primary-700: #9010A1;
  --rs-primary-800: #9010A1;
  --rs-primary-900: #73007D;
  --rs-red-50: #FFF2F2;
  --rs-red-100: #FCCFCF;
  --rs-red-200: #FAA9A7;
  --rs-red-300: #FA8682;
  --rs-red-400: #F7635C;
  --rs-red-500: #F44336;
  --rs-red-600: #EB3626;
  --rs-red-700: #D62915;
  --rs-red-800: #B81C07;
  --rs-red-900: #8F1300;
  --rs-orange-50: #fff9f2;
  --rs-orange-100: #ffe2c2;
  --rs-orange-200: #ffce91;
  --rs-orange-300: #ffba61;
  --rs-orange-400: #ffa930;
  --rs-orange-500: #ff9800;
  --rs-orange-600: #f59700;
  --rs-orange-700: #e08e00;
  --rs-orange-800: #c27e00;
  --rs-orange-900: #960;
  --rs-yellow-50: #fff9f0;
  --rs-yellow-100: #ffedd1;
  --rs-yellow-200: #ffe2b3;
  --rs-yellow-300: #ffd894;
  --rs-yellow-400: #ffcf75;
  --rs-yellow-500: #ffc757;
  --rs-yellow-600: #f5bb3d;
  --rs-yellow-700: #e0a824;
  --rs-yellow-800: #c28f0e;
  --rs-yellow-900: #997000;
  --rs-green-50: #f3fff2;
  --rs-green-100: #cff0ce;
  --rs-green-200: #aee0ad;
  --rs-green-300: #8ccf8c;
  --rs-green-400: #71bf72;
  --rs-green-500: #58b15b;
  --rs-green-600: #3fab45;
  --rs-green-700: #27a12f;
  --rs-green-800: #10911b;
  --rs-green-900: #007d0c;
  --rs-cyan-50: #f2ffff;
  --rs-cyan-100: #bcf4f7;
  --rs-cyan-200: #87e6ed;
  --rs-cyan-300: #57dae6;
  --rs-cyan-400: #2acadb;
  --rs-cyan-500: #00bcd4;
  --rs-cyan-600: #00b1cc;
  --rs-cyan-700: #00a0bd;
  --rs-cyan-800: #008aa6;
  --rs-cyan-900: #006e87;
  --rs-blue-50: #edf9ff;
  --rs-blue-100: #c0e8fc;
  --rs-blue-200: #93d6fa;
  --rs-blue-300: #67c1f5;
  --rs-blue-400: #3dadf2;
  --rs-blue-500: #1499ef;
  --rs-blue-600: #0e8ce6;
  --rs-blue-700: #087ad1;
  --rs-blue-800: #0464b3;
  --rs-blue-900: #00498a;
  --rs-violet-50: #f6f2ff;
  --rs-violet-100: #d5c9f0;
  --rs-violet-200: #b6a1e3;
  --rs-violet-300: #987bd4;
  --rs-violet-400: #805ac7;
  --rs-violet-500: #673ab7;
  --rs-violet-600: #5f2bb3;
  --rs-violet-700: #531ba8;
  --rs-violet-800: #470c99;
  --rs-violet-900: #390085;
  --rs-state-success: #58b15b;
  --rs-state-info: #1499ef;
  --rs-state-warning: #ffc757;
  --rs-state-error: #f04f43;
  --rs-body: var(--rs-gray-50);
  --rs-text-link: var(--navbar-color);
  --rs-text-link-hover: var(--navbar-color);
  --rs-text-link-active: var(--navbar-color);
  --rs-text-primary: var(--text-title);
  --rs-text-secondary: #a4a9b3;
  --rs-text-tertiary: #858b94;
  --rs-text-heading: var(--rs-gray-900);
  --rs-text-inverse: var(--rs-gray-800);
  --rs-text-heading-inverse: var(--rs-gray-900);
  --rs-text-active: var(--rs-primary-500);
  --rs-text-disabled: #5c6066;
  --rs-border-primary: var(--rs-gray-400);
  --rs-border-secondary: #292d33;
  --rs-bg-card: var(--rs-gray-800);
  --rs-bg-overlay: var(--rs-gray-50);
  --rs-bg-well: var(--rs-gray-900);
  --rs-bg-active: var(--rs-primary-700);
  --rs-bg-backdrop: #161616cc;
  --rs-state-hover-bg: #3c3f43;
  --rs-color-focus-ring: 0 0 0 3px var(--primary-op-25);
  --rs-state-focus-shadow: 0 0 0 3px var(--primary-op-25);
  --rs-state-focus-outline: 3px solid var(--primary-op-25);
  --rs-shadow-overlay: 0 4px 4px rgba(0, 0, 0, 0.12), 0 0 10px rgba(0, 0, 0, 0.06);
  --rs-btn-default-bg: #3c3f43;
  --rs-btn-default-text: #e9ebf0;
  --rs-btn-default-hover-bg: #5c6066;
  --rs-btn-default-active-bg: #858b94;
  --rs-btn-default-active-text: #fff;
  --rs-btn-default-disabled-bg: #3c3f43;
  --rs-btn-default-disabled-text: #5c6066;
  --rs-btn-primary-bg: var(--rs-primary-400);
  --rs-btn-primary-text: var(--rs-primary-50);
  --rs-btn-primary-hover-bg: var(--rs-primary-300);
  --rs-btn-primary-active-bg: var(--rs-primary-500);
  --rs-btn-subtle-text: #a4a9b3;
  --rs-btn-subtle-hover-bg: var(--rs-gray-200);
  --rs-btn-subtle-hover-text: #eee;
  --rs-btn-subtle-active-bg: var(--rs-gray-300);
  --rs-btn-subtle-active-text: #eee;
  --rs-btn-subtle-disabled-text: #5c6066;
  --rs-btn-ghost-border: var(--rs-primary-400);
  --rs-btn-ghost-text: var(--rs-primary-400);
  --rs-btn-ghost-hover-border: var(--rs-primary-300);
  --rs-btn-ghost-hover-text: var(--rs-primary-300);
  --rs-btn-ghost-active-border: var(--rs-primary-500);
  --rs-btn-ghost-active-text: var(--rs-primary-500);
  --rs-btn-link-text: var(--rs-primary-500);
  --rs-btn-link-hover-text: var(--rs-primary-400);
  --rs-btn-link-active-text: var(--rs-primary-200);
  --rs-iconbtn-addon: #5c6066;
  --rs-iconbtn-activated-addon: #6a6f76;
  --rs-iconbtn-pressed-addon: #a4a9b3;
  --rs-iconbtn-primary-addon: var(--rs-primary-600);
  --rs-iconbtn-primary-activated-addon: var(--rs-primary-500);
  --rs-iconbtn-primary-pressed-addon: var(--rs-primary-400);
  --rs-divider-border: #3c3f43;
  --rs-loader-ring: var(--rs-primary-300);
  --rs-loader-rotor: var(--rs-primary-100);
  --rs-loader-backdrop: rgba(15, 19, 26, 0.83);
  --rs-loader-ring-inverse: var(--rs-primary-100);
  --rs-loader-rotor-inverse: #5c6066;
  --rs-loader-backdrop-inverse: hsla(0, 0%, 100%, 0.9);
  --rs-message-success-header: #fff;
  --rs-message-success-text: #575757;
  --rs-message-success-icon: #378F17;
  --rs-message-success-bg: #CFEBC0;
  --rs-message-info-header: #fff;
  --rs-message-info-text: #fff;
  --rs-message-info-icon: #fff;
  --rs-message-info-bg: #2196f3;
  --rs-message-warning-header: var(--rs-gray-900);
  --rs-message-warning-text: var(--rs-gray-900);
  --rs-message-warning-icon: var(--rs-gray-900);
  --rs-message-warning-bg: #ffb300;
  --rs-message-error-header: #fff;
  --rs-message-error-text: #fff;
  --rs-message-error-icon: #fff;
  --rs-message-error-bg: #f44336;
  --rs-tooltip-bg: #5c6066;
  --rs-tooltip-text: #fff;
  --rs-progress-bg: #292d33;
  --rs-progress-bar: var(--rs-primary-500);
  --rs-progress-bar-success: #4caf50;
  --rs-progress-bar-fail: #f44336;
  --rs-placeholder: #3c3f43;
  --rs-placeholder-active: #484c50;
  --rs-dropdown-divider: #3c3f43;
  --rs-dropdown-item-bg-hover: #3c3f43;
  --rs-dropdown-item-bg-active: var(--primary-op-20);
  --rs-dropdown-item-text-active: var(--rs-primary-500);
  --rs-dropdown-header-text: #5c6066;
  --rs-dropdown-shadow: 0 0 10px 1px rgba(0, 0, 0, 0.2), 0 4px 4px 3px rgba(0, 0, 0, 0.24);
  --rs-menuitem-active-bg: #3c3f43;
  --rs-menuitem-active-text: currentColor;
  --rs-steps-border: #a4a9b3;
  --rs-steps-state-finish: var(--rs-primary-500);
  --rs-steps-border-state-finish: var(--rs-primary-500);
  --rs-steps-state-wait: #a4a9b3;
  --rs-steps-state-process: var(--rs-primary-700);
  --rs-steps-state-error: #f44336;
  --rs-steps-border-state-error: #f44336;
  --rs-steps-icon-state-process: var(--rs-primary-500);
  --rs-steps-icon-state-error: #f44336;
  --rs-navs-text: #a4a9b3;
  --rs-navs-text-hover: #cbced4;
  --rs-navs-bg-hover: #6a6f76;
  --rs-navs-text-active: #fff;
  --rs-navs-bg-active: #6a6f76;
  --rs-navs-tab-border: #3c3f43;
  --rs-navs-subtle-border: #3c3f43;
  --rs-navs-selected: var(--rs-primary-500);
  --rs-navbar-default-bg: var(--rs-gray-800);
  --rs-navbar-default-text: #fff;
  --rs-navbar-default-selected-text: var(--rs-primary-500);
  --rs-navbar-default-hover-bg: var(--rs-navbar-default-bg);
  --rs-navbar-default-hover-text: #e9ebf0;
  --rs-navbar-inverse-bg: var(--rs-primary-700);
  --rs-navbar-inverse-text: #fff;
  --rs-navbar-inverse-selected-bg: var(--rs-primary-400);
  --rs-navbar-inverse-hover-bg: var(--rs-primary-600);
  --rs-navbar-inverse-hover-text: #fff;
  --rs-navbar-subtle-bg: transparent;
  --rs-navbar-subtle-text: #a4a9b3;
  --rs-navbar-subtle-selected-text: var(--rs-primary-500);
  --rs-navbar-subtle-hover-bg: #292d33;
  --rs-navbar-subtle-hover-text: #e9ebf0;
  --rs-sidenav-default-bg: var(--rs-gray-800);
  --rs-sidenav-default-text: #a4a9b3;
  --rs-sidenav-default-selected-text: var(--rs-primary-500);
  --rs-sidenav-default-hover-bg: #292d33;
  --rs-sidenav-default-hover-text: #e9ebf0;
  --rs-sidenav-default-footer-border: #3c3f43;
  --rs-sidenav-inverse-bg: var(--rs-primary-700);
  --rs-sidenav-inverse-text: #fff;
  --rs-sidenav-inverse-selected-bg: var(--rs-primary-400);
  --rs-sidenav-inverse-hover-bg: var(--rs-primary-600);
  --rs-sidenav-inverse-footer-border: var(--rs-primary-600);
  --rs-sidenav-subtle-bg: transparent;
  --rs-sidenav-subtle-text: #a4a9b3;
  --rs-sidenav-subtle-selected-text: var(--rs-primary-500);
  --rs-sidenav-subtle-hover-bg: #292d33;
  --rs-sidenav-subtle-hover-text: #e9ebf0;
  --rs-sidenav-subtle-footer-border: #3c3f43;
  --rs-input-bg: var(--rs-gray-50);
  --rs-input-focus-border: var(--rs-primary-500);
  --rs-input-disabled-bg: #292d33;
  --rs-listbox-option-hover-bg: #3c3f43;
  --rs-listbox-option-hover-text: currentColor;
  --rs-listbox-option-selected-text: var(--rs-primary-500);
  --rs-listbox-option-selected-bg: var(--primary-op-20);
  --rs-listbox-option-disabled-text: #5c6066;
  --rs-listbox-option-disabled-selected-text: var(--rs-primary-200);
  --rs-checkbox-icon: var(--rs-gray-800);
  --rs-checkbox-border: #6a6f76;
  --rs-checkbox-checked-bg: var(--rs-primary-500);
  --rs-checkbox-disabled-bg: #5c6066;
  --rs-radio-marker: var(--rs-gray-800);
  --rs-radio-border: #6a6f76;
  --rs-radio-checked-bg: var(--rs-primary-500);
  --rs-radio-disabled-bg: #5c6066;
  --rs-rate-symbol: #3c3f43;
  --rs-rate-symbol-checked: #ffb300;
  --rs-toggle-bg: #6a6f76;
  --rs-toggle-thumb: #fff;
  --rs-toggle-hover-bg: #858b94;
  --rs-toggle-disabled-bg: #3c3f43;
  --rs-toggle-disabled-thumb: #5c6066;
  --rs-toggle-checked-bg: var(--rs-primary-700);
  --rs-toggle-checked-thumb: #fff;
  --rs-toggle-checked-hover-bg: var(--rs-primary-600);
  --rs-toggle-checked-disabled-bg: var(--rs-primary-900);
  --rs-toggle-checked-disabled-thumb: #858b94;
  --rs-slider-bar: #3c3f43;
  --rs-slider-hover-bar: #3c3f43;
  --rs-slider-thumb-border: var(--rs-primary-500);
  --rs-slider-thumb-bg: #292d33;
  --rs-slider-thumb-hover-shadow: 0 0 0 8px var(--primary-op-25);
  --rs-slider-progress: var(--rs-primary-500);
  --rs-uploader-item-bg: #858b94;
  --rs-uploader-item-hover-bg: #3c3f43;
  --rs-uploader-overlay-bg: rgba(60, 63, 67, 0.8);
  --rs-uploader-dnd-bg: #292d33;
  --rs-uploader-dnd-border: #a4a9b3;
  --rs-uploader-dnd-hover-border: var(--rs-primary-500);
  --rs-avatar-bg: #6a6f76;
  --rs-avatar-text: #fff;
  --rs-badge-bg: #f44336;
  --rs-badge-text: #fff;
  --rs-tag-bg: #3c3f43;
  --rs-tag-close: #f44336;
  --rs-carousel-bg: #3c3f43;
  --rs-carousel-indicator: hsla(0, 0%, 100%, 0.4);
  --rs-carousel-indicator-hover: #fff;
  --rs-carousel-indicator-active: var(--rs-primary-500);
  --rs-panel-shadow: 0 4px 4px rgba(0, 0, 0, 0.12), 0 0 10px rgba(0, 0, 0, 0.06);
  --rs-list-bg: var(--rs-gray-900);
  --rs-list-border: #292d33;
  --rs-list-hover-bg: #3c3f43;
  --rs-list-placeholder-bg: var(--primary-op-20);
  --rs-list-placeholder-border: var(--rs-primary-500);
  --rs-timeline-indicator-bg: #5c6066;
  --rs-timeline-indicator-active-bg: var(--rs-primary-500);
  --rs-table-shadow: rgba(9, 9, 9, 0.99);
  --rs-table-sort: var(--rs-primary-500);
  --rs-table-resize: var(--rs-primary-500);
  --rs-table-scrollbar-track: #292d33;
  --rs-table-scrollbar-thumb: #a4a9b3;
  --rs-table-scrollbar-thumb-active: #cbced4;
  --rs-table-scrollbar-vertical-track: #292d33;
  --rs-drawer-shadow: 0 4px 4px rgba(0, 0, 0, 0.12), 0 0 10px rgba(0, 0, 0, 0.06);
  --rs-modal-shadow: 0 4px 4px rgba(0, 0, 0, 0.12), 0 0 10px rgba(0, 0, 0, 0.06);
  --rs-form-errormessage-text: #fff;
  --rs-form-errormessage-bg: #f44336;
  --rs-form-errormessage-border: #f44336;
  --rs-picker-value: var(--rs-primary-500);
  --rs-picker-count-bg: var(--rs-primary-700);
  --rs-picker-count-text: #fff;
  --rs-calendar-today-bg: var(--rs-primary-700);
  --rs-calendar-today-text: #fff;
  --rs-calendar-range-bg: var(--primary-op-80);
  --rs-calendar-time-unit-bg: #3c3f43;
  --rs-calendar-date-selected-text: #fff;
  --rs-popover-shadow: 0 4px 6px rgba(0, 0, 0, 0.3);
}